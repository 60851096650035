@use "@ag-grid-community/styles" as ag;
@include ag.grid-styles((theme: metronic,
    extend-theme: material));

// // More kinds of customisation can be made with CSS rules
.ag-theme-metronic {

  --ag-grid-size: 5px;

  --ag-font-family: var(--bs-body-font-family);
  --ag-header-foreground-color: #a1a5b7;
  --ag-header-cell-hover-background-color: transparent;
  --ag-range-selection-border-color: transparent;

  --ag-font-size: 1.075rem !important; // From .fs-6

  --ag-odd-row-background-color: #fafafa;

  --ag-selected-row-background-color: #ddd;

  --ag-row-hover-color: #eee;

  .ag-details-grid {
    border: 0px solid #ddd;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }

  .ag-header {
    border-bottom: 0;
  }

  .ag-header-cell,
  .ag-header-group-cell,
  .ag-cell[col-id="expandFake"] {
    /* padding-left: 5px;
    padding-right: 5px; */
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .ag-header-cell-text {
    margin: 0 auto;
  }

  .ag-cell-wrap-text {
    word-break: break-word !important;
  }

  .ag-cell-center {
    text-align: center;
  }

  /*.ag-row {
    border-bottom: 0;
  }*/

  .ag-header-row {
    font-size: 1.25rem !important; // From class .fs-4
  }

  .ag-header-cell, .ag-header-cell-label {
    align-items: flex-start;
  }

  .ag-header-cell-comp-wrapper,
  .ag-header-cell-comp-wrapper .ag-cell-label-container {
    padding-top: 0;
  }
}
