//
// Custom omics box css 
//
body {
    --ob-viewer-manager-border: var(--bs-secondary);
}

%history_Tag {
    border: 1px solid black;
    border-radius: 5px;
    padding: 0 5px;
}

.tag_Running {
    @extend %history_Tag;
    background-color: #c6e0b4;
}

.tag_Done {
    @extend %history_Tag;
    background-color: #fff;
}

.tag_Error {
    @extend %history_Tag;
    background-color: #fbcbaa;
}

.tag_Canceled {
    @extend %history_Tag;
    background-color: #ffe699;
}

div.historyTag {
    display: inline-block;
    min-width: 130px;
}

div.historyTag_expanded {
    padding-left: 70px;
}

th.historyTHeader {
    position: sticky;
    top: -10px;
    z-index: 1;
    background-color: #fff;
}

.agHistory_filter {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.agHistory_filter button i {
    font-size: 19px;
}

div.collapsed-viewer {
    width: 200px;
    padding: 15px;
    box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%) !important;
    border: 0;
    border-radius: calc(0.625rem - 1px) calc(0.625rem - 1px) 0 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    background-color: white;
    margin-right: 20px;
}

div.collapsed-viewer h2 {
    margin: 0;
}

#minimized_cards_holder {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-items: flex-start;
    align-items: center;
    position: sticky;
    bottom: 0;
}

div.collapsible-holder {
    position: relative;
    min-height: 500px
}

div.collapsible-holder:empty,
div.collapsible-holder[data-collapsed="true"] {
    display: none;
}

button.collapse-icon {
    display: flex;
    align-items: flex-start;
    border: 0;
    padding: 0;
    background: transparent;
}

button.collapse-icon-top {
    position: absolute;
    right: 8px;
    top: 5px;
}

div#drop_zone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: border .1s ease-in-out;
}

div.dragging {
    border-color: #2196f3;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: aliceblue;
    opacity: 0.6;
    color: #bdbdbd;
    outline: none;
}


body.authPage {
    background-color: #909090;

    a {
        color: #337ab7 !important;
        text-decoration: none;

        &:hover {
            text-decoration: underline !important;
        }
    }

    /* Cognito styles */
    /*body {
    font-family: "Arial";
    color: #3B3B3B;
    background: #909090;
}*/

    /* This is needed for centering*/
    .modal-dialog-cognito {
        position: relative;

        label {
            font-weight: bold;
        }

        .modal-body {
            /* Restore padding used in old bootstrap */
            padding: 1.75rem;
        }
    }

    .google-button {
        padding: 1px !important;
        margin: 1px;
    }

    @media (min-width: 768px) {
        .modal-dialog-cognito {
            width: 700px;
            margin: 30px auto;
        }

        .modal-content-desktop {
            min-width: 700px;
            -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
            box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
        }

        .login-or {
            display: none !important;
        }
    }

    @media (max-width: 767px) {
        .visible-xs {
            display: block !important;
        }

        .panel-right-border, .panel-left-border{
            border: 0 !important;
        }

        .span-or-verical {
            display: none !important;
        }
    }

    .logo-customizable {
        max-width: 100%;
        max-height: 100%;
    }

    .banner-customizable {
        padding: 25px 0px 25px 0px;
        background-color: white;
    }

    .label-customizable {
        font-weight: 400;
    }

    .textDescription-customizable {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        font-size: 16px;
    }

    .idpDescription-customizable {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        font-size: 16px;
    }

    .legalText-customizable {
        color: #747474;
        font-size: 11px;
    }

    .submitButton-customizable {
        font-size: 14px;
        font-weight: bold;
        margin: 15px 0 15px 0 !important;
        height: 40px;
        width: 100%;
        color: #fff !important;
        background-color: rgb(27, 44, 103) !important;
    }

    .btn.btn-primary:hover:not(.btn-active).submitButton-customizable:hover,
    .btn.btn-primary:focus:not(.btn-active).submitButton-customizable {
        color: #fff !important;
        background-color: #071127 !important;
    }

    .errorMessage-customizable {
        padding: 5px;
        font-size: 14px;
        width: 100%;
        background: #FFFFFF;
        border: 2px solid #D64958;
        color: #D64958;
    }

    .inputField-customizable {
        width: 100%;
        height: 34px;
        color: #555;
        background-color: #fff;
        border: 1px solid rgb(230, 230, 230);
    }

    .inputField-customizable:focus {
        border-color: #66afe9;
        outline: 0;
    }

    .idpButton-customizable {
        height: 40px;
        width: 100%;
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        color: #fff;
        background-color: #5bc0de;
        border-color: #46b8da;
    }

    .idpButton-customizable:hover {
        color: #fff;
        background-color: #31b0d5;
    }

    .socialButton-customizable {
        border-radius: 2px;
        height: 40px;
        margin-bottom: 15px;
        padding: 1px;
        text-align: left;
        width: 100%;
    }

    .redirect-customizable {
        text-align: center;
    }

    .passwordCheck-notValid-customizable {
        color: #DF3312;
    }

    .passwordCheck-valid-customizable {
        color: #19BF00;
    }

    .background-customizable {
        background-color: #fff;
    }

    /* Cognito: not custom */
    .panel {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
        border-radius: 0;
        background: transparent;
    }

    .modal-content {
        width: 100% !important;
        margin: 0px auto;
        border: 0px;
        overflow: hidden;
        border-radius: 0%;
    }

    .modal-content-mobile {
        max-width: 350px;
    }

    @media (max-width: 991px) {
        .modal-content-desktop {
            max-width: 350px;
        }
    }

    @media (min-width: 992px) {
        .modal-content-desktop {
            min-width: 700px;
        }
    }

    .login-or {
        display: block;
        position: relative;
        font-size: 16px;
        padding-top: 7px;
        padding-bottom: 7px;
        margin-top: 8px;
    }

    .hr-or {
        color: #cdcdcd;
        background-color: #cdcdcd;
        height: 1px;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .panel-left-border {
        border-left-style: solid;
        border-left-color: #F5F5F5;
        border-left-width: 2px;
    }

    .panel-right-border {
        border-right-style: solid;
        border-right-color: #F5F5F5;
        border-right-width: 2px;
        margin-right: -2px;
    }

    .span-or {
        display: block;
        position: absolute;
        left: 50%;
        top: -2px;
        margin-left: -25px;
        background-color: #fff;
        text-align: center;
        padding: 0px 5px 0px 5px;
        color: #A3A3A3;
    }

    .span-or-verical {
        font-size: 16px;
        display: block;
        position: absolute;
        top: 40%;
        margin-left: -25px;
        background-color: #fff;
        text-align: center;
        color: #A3A3A3;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    @media (max-width: 992px) {
        .panels-responsive {
            border: none;
            padding: 0;
            margin: 0;
        }
    }

    .socialButton-customizable.facebook-button {
        background-color: #4267b2;
        color: white;
        font-weight: 600;
    }

    .socialButton-customizable.facebook-button:hover {
        background-color: #2955ad;
        color: white;
    }

    .socialButton-customizable.google-button {
        background: #4285F4;
        color: #FFF;
        font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
        font-weight: 500;
    }

    .socialButton-customizable.google-button:hover {
        background: #3f7ce8;
        color: #EEE;
    }

    .socialButton-customizable.amazon-button {
        background-image: linear-gradient(#ffe8ab, #f5c645);
        border: 1px solid #b38b22;
        color: black;
    }

    .socialButton-customizable.amazon-button:hover {
        background-image: linear-gradient(#ebd498, #e1b144);
        border: 1px solid #b38b22;
    }

    .socialButton-customizable.apple-button {
        background-color: #000;
        color: #FFF;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    }

    .socialButton-customizable.apple-button:hover {
        background-color: #000;
        color: #CCC;
    }

    .socialButton-customizable .social-logo {
        display: inline-block;
        height: 100%;
        margin-right: 12px;
        padding: 9px;
        text-align: center;
        vertical-align: middle;
    }

    .socialButton-customizable.facebook-button .social-logo {
        padding: 6px;
    }

    .socialButton-customizable.google-button .social-logo {
        background: #FFF;
        border-radius: 2px;
    }

    .socialButton-customizable.amazon-button .social-logo {
        padding: 7px;
    }

    .socialButton-customizable.apple-button .social-logo {
        margin-top: -3px;
        padding: 8px;
    }

    .backButton {
        height: 40px;
        width: 100%;
    }

    .center-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .center-column {
        justify-content: center;
        display: flex;
    }

    .mfa-image-icon-size {
        width: 80px;
        height: 105px;
        margin-right: 5px;
    }

    .divider {
        border: 1px solid #ABB5BE;
    }

    .mfa-totp-qr-code {
        width: 115px;
        height: 115px;
        margin-right: 15px;
    }

    .mfa-container {
        padding: 10px;
    }

    .mfa-totp-secret-key {
        word-wrap: break-word;
        margin-top: 20px;
    }

    .success-message {
        padding: 5px;
        font-size: 14px;
        width: 100%;
        background: #F5F5F5;
        border: 2px solid #28a745;
        color: #28a745;
    }

    .back-button {
        height: 40px;
        width: 100%;
    }
}

.loader {
    animation: spin 1s steps(8) infinite;
    height: 1rem;
}

.context-menu {
    --contexify-menu-bgColor: #fff;
    --contexify-separator-color: rgba(0,0,0,.2);
    --contexify-item-color: #333;
    --contexify-activeItem-color: #fff;
    --contexify-activeItem-bgColor: #3498db;
    --contexify-rightSlot-color: #6f6e77;
    --contexify-activeRightSlot-color: #fff;
    --contexify-arrow-color: #6f6e77;
    --contexify-activeArrow-color: #fff;
}

.contexify_rightSlot {
    color: var(--contexify-rightSlot-color);
    display: flex;
    margin-left: auto;
}